import React from "react"
import { v4 as uuidv4 } from "uuid"
import Img from "gatsby-image"

//styles
import ProjectPageStyles from "./ProjectPage.module.scss"

export default function ProjectCard({ allProjects }) {
  const { node: project } = allProjects

  return (
    <div className={ProjectPageStyles.border}>
      <li key={uuidv4()} className={ProjectPageStyles.projectCard}>
        <Img
          fluid={project.frontmatter.images.childImageSharp.fluid}
          alt={`image of ${project.frontmatter.title}`}
          className={ProjectPageStyles.projectImg}
        />
        <h1 className={ProjectPageStyles.projectTitle}>
          {project.frontmatter.title}
        </h1>
        <ul className={ProjectPageStyles.projectTech}>
          {project.frontmatter.tech.map(techUsed => {
            return <li key={techUsed}>{techUsed}</li>
          })}
        </ul>
        <a
          href={project.frontmatter.github_repo_url}
          target="_blank"
          rel="noopener noreferrer"
          className={ProjectPageStyles.github}
        >
          <i className="ri-github-fill" style={{ fontSize: 20 }}></i>
        </a>
        <a
          href={project.frontmatter.hosted_url}
          target="_blank"
          rel="noopener noreferrer"
          className={ProjectPageStyles.url}
        >
          <i
            className="ri-external-link-line"
            style={{ fontSize: 20 }}
            title="external-link-icon"
          ></i>
        </a>
      </li>
    </div>
  )
}
