import React from "react"
import ProjectPage from "../components/Projects/ProjectPage"
import Seo from "../components/Seo/Seo"

export default function Projects({ location }) {
  return (
    <>
      <Seo title="ChemToCode | Projects" pathname={location.pathname} />
      <ProjectPage />
    </>
  )
}
