import React from "react"
import { v4 as uuidv4 } from "uuid"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

// graphql
import { ProjectPosts } from "../graphqlQueries/ProjectPosts"

//components
import ProjectCard from "./ProjectCard"
import ProjectPageDesktop from "./ProjectPageDesktop"
import MobileSvg from "../MobileSVG/MobileSvg"

//styles
import ProjectPageStyles from "./ProjectPage.module.scss"

export default function ProjectPage() {
  const { allMdx: projects } = ProjectPosts()
  const breakpoints = useBreakpoint()
  return (
    <>
      <div className={ProjectPageStyles.container}>
        <div className={ProjectPageStyles.wrapper}>
          <ul>
            {projects.edges.map(project => {
              return <ProjectCard key={uuidv4()} allProjects={project} />
            })}
          </ul>
        </div>
        {breakpoints.md ? <MobileSvg /> : <ProjectPageDesktop />}
      </div>
    </>
  )
}
