import React from "react"

//styles
import ProjectPageStyles from "./ProjectPage.module.scss"

export default function ProjectPageDesktop() {
  return (
    <div className={ProjectPageStyles.projectSvg}>
      <svg
        aria-labelledby="projectSvg"
        role="img"
        viewBox="0 0 1173 555"
        preserveAspectRatio="xMinYMin meet"
        width="90%"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title id="projectSvg">
          Project svg with desk and test tube bubbles with cloud animations.
        </title>
        <path
          d="M1146.5 491H47.5c-6.6 0-12-5.4-12-12v-35h1123v35c0 6.6-5.4 12-12 12z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M1163 444H31c-5.5 0-10-4.5-10-10s4.5-10 10-10h1132c5.5 0 10 4.5 10 10s-4.5 10-10 10z"
          fill="#EACDA3"
        />
        <g opacity=".3">
          <path
            d="M839.3 423.5h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M846.3 423.5h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9v84.3c0 2.8-2.3 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M842.7 419.9h-38.6c-2.8 0-5-2.3-5-5v-49.8h48.7v49.8c-.1 2.7-2.3 5-5.1 5z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M852.6 334.2h-58.5c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8h58.5c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M823.4 367.8c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
            fill="#F5AFAE"
          />
          <path
            opacity=".27"
            d="M842 334.2v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5H842zm-35.9 65.6c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.1-1.7 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <path
            d="M818.1 347.6h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.2h-19.8v.4h19.8v-.4zM805.7 353h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.2h-7.1v.4h7.1v-.4zm0 8.7h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4zm0 9.2h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.2h-7.1v.4h7.1v-.4zm0 8.7h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4z"
            fill="#1E1E1C"
          />
          <path
            d="M909.2 423.5h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M916.1 423.5h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9v84.3c.1 2.8-2.2 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M912.5 419.9h-38.6c-2.8 0-5-2.3-5-5v-49.8h48.7v49.8c0 2.7-2.3 5-5.1 5z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M922.5 334.2H864c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8h58.5c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M893.2 367.8c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
            fill="#B3DAD0"
          />
          <path
            opacity=".27"
            d="M911.8 334.2v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5h-9.8zM876 399.8c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.1-1.8 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <path
            d="M887.9 347.6h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.2h-19.8v.4h19.8v-.4zM875.5 353h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.2h-7.1v.4h7.1v-.4zm0 8.7h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4zm0 9.2h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.2h-7.1v.4h7.1v-.4zm0 8.7h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4z"
            fill="#1E1E1C"
          />
          <path
            d="M980.7 424.1h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M987.6 424.1h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9V419c.1 2.8-2.2 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M984 420.4h-38.6c-2.8 0-5-2.3-5-5v-49.8H989v49.8c0 2.7-2.2 5-5 5z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M994 334.7h-58.5c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8H994c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M964.7 368.3c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
            fill="#F5AFAE"
          />
          <path
            opacity=".27"
            d="M983.3 334.7v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5h-9.8zm-35.9 65.6c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.2-1.7 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <path
            d="M959.4 348.1h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zm0 15.2h-19.8v.4h19.8v-.4zm0 15.1h-19.8v.4h19.8v-.4zM947 353.5h-7.1v.4h7.1v-.4zm0-3.2h-7.1v.4h7.1v-.4zm0 6.4h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4zm0 8.6h-7.1v.4h7.1v-.4zm0-3.2h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.2h-7.1v.4h7.1v-.4zm0 9.2h-7.1v.4h7.1v-.4zm0-3.3h-7.1v.4h7.1v-.4zm0 6.5h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4zm0 8.6h-7.1v.4h7.1v-.4zm0-3.2h-7.1v.4h7.1v-.4zm0 6.4h-7.1v.4h7.1v-.4zm0 3.3h-7.1v.4h7.1v-.4z"
            fill="#1E1E1C"
          />
          <path
            d="M802.1 414.8c-1.5-.5-2.3-2.2-1.7-3.7l53.8-150.2c.5-1.5 2.2-2.3 3.7-1.7 1.5.5 2.3 2.2 1.7 3.7l-53.8 150.2c-.6 1.4-2.3 2.2-3.7 1.7z"
            fill="#F5F5F5"
          />
          <path
            opacity=".27"
            d="M857.8 259.2c-.5-.2-1-.2-1.5 0 .6.9.7 2.3.2 3.6l-53.2 148.4c-.5 1.3-1.4 2.3-2.5 2.6.3.5.7.8 1.2 1 1.5.5 3.3-.6 4-2.6l53.2-148.4c.7-2 .1-4.1-1.4-4.6z"
            fill="#D8D8D8"
          />
        </g>
        <g opacity=".3">
          <path
            d="M192 380.4v-5.8h-28.5v5.8c0 7.3-5.9 13.3-13.3 13.3-12.9 0-23.3 10.4-23.3 23.3v7.6h101.7V417c0-12.9-10.4-23.3-23.3-23.3-7.3 0-13.3-6-13.3-13.3z"
            fill="#F4F3F4"
          />
          <path
            d="M313.6 374.6H41.9c-2.1 0-3.9-1.7-3.9-3.9V207.9c0-2.1 1.7-3.9 3.9-3.9h271.7c2.1 0 3.9 1.7 3.9 3.9v162.9c0 2.1-1.8 3.8-3.9 3.8z"
            fill="#808181"
          />
          <path
            d="M177.8 363.6a5.5 5.5 0 100-11 5.5 5.5 0 100 11zm129.1-22.1H48.6c-2.1 0-3.9-1.7-3.9-3.9V213.5c0-2.1 1.7-3.9 3.9-3.9h258.3c2.1 0 3.9 1.7 3.9 3.9v124.1c-.1 2.2-1.8 3.9-3.9 3.9z"
            fill="#F4F3F4"
          />
          <path
            d="M236.3 374.6c-.6 55.7 60.1-12.8 60.7 36.8"
            stroke="#4E4D4D"
            strokeWidth="1.654"
            strokeMiterlimit="10"
          />
          <path
            d="M307.9 425.1h-26.4c-1.3 0-2.3-1.1-2.3-2.3v-5.2c0-3.7 3-6.7 6.7-6.7h17.7c3.7 0 6.7 3 6.7 6.7v5.2c-.1 1.3-1.1 2.3-2.4 2.3z"
            fill="#1B1B1B"
          />
        </g>
        <g opacity=".3">
          <path
            d="M364.1 362.8c-2.4 0-4.3-1.9-4.3-4.3v-8.3h-18.4v8.3c0 2.4-1.9 4.3-4.3 4.3-2.2 0-4 1.8-4 4v48.9c0 4.2 3.4 7.5 7.5 7.5h20.1c4.2 0 7.5-3.4 7.5-7.5v-48.9c0-2.1-1.9-4-4.1-4z"
            fill="url(#paint4_linear)"
          />
          <path
            opacity=".1"
            d="M364.1 362.8c-2.4 0-4.3-1.9-4.3-4.3v-8.3h-6.9v8.3c0 2.4 1.9 4.3 4.3 4.3 2.2 0 4 1.8 4 4v48.9c0 4.2-3.4 7.5-7.5 7.5h6.9c4.2 0 7.5-3.4 7.5-7.5v-48.9c.1-2.1-1.8-4-4-4z"
            fill="#D8D8D8"
          />
          <path
            d="M333.1 403.7h16.3c3.3 0 6-2.7 6-6v-12.6c0-3.3-2.7-6-6-6H333v24.6h.1z"
            fill="#3D3D3B"
          />
          <path
            d="M333.4 402.5h18.5c.9 0 1.7-.7 1.7-1.5v-19.2c0-.8-.8-1.5-1.7-1.5H333"
            stroke="#fff"
            strokeWidth=".137"
            strokeMiterlimit="10"
          />
          <path
            d="M359.4 352.1h-17.6c-1.4 0-2.5-1.1-2.5-2.5v-3.1c0-1.4 1.1-2.5 2.5-2.5h17.6c1.4 0 2.5 1.1 2.5 2.5v3.1c0 1.4-1.1 2.5-2.5 2.5z"
            fill="#3A65AE"
          />
          <path
            d="M341.7 345.2v5.8m2.3-5.8v5.8m2.2-5.8v5.8m2.1-5.8v5.8m2.3-5.8v5.8m2.2-5.8v5.8m1.9-5.8v5.8m2.3-5.8v5.8m2-5.8v5.8m-17.3-5.8v5.8m2.3-5.8v5.8"
            stroke="#1E1E1C"
            strokeWidth=".457"
            strokeMiterlimit="10"
          />
          <path
            opacity=".1"
            d="M360.6 344h-7.8c1.8 0 3.3 1.5 3.3 3.4v1.4c0 1.9-1.5 3.4-3.3 3.4h7.8c.7 0 1.3-.6 1.3-1.3v-5.5c0-.8-.6-1.4-1.3-1.4z"
            fill="#D8D8D8"
          />
        </g>
        <g opacity=".3">
          <path
            d="M635.2 319.4H611V394c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zm.8 0h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7H636c2 0 3.7 1.7 3.7 3.7v.7c0 2.1-1.7 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M623.2 409.7c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4v49.8c0 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint5_linear)"
          />
          <path
            d="M623.3 349.9c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint6_linear)"
          />
          <path
            opacity=".27"
            d="M631 319.4v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4H631z"
            fill="#D8D8D8"
          />
          <path
            opacity=".1"
            d="M616.5 387c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c0 1.4-1.2 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
          <path
            d="M734.5 319.4h-24.2V394c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zm.7 0h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7h25.4c2 0 3.7 1.7 3.7 3.7v.7c0 2.1-1.6 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M722.5 409.7c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4v49.8c-.1 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint7_linear)"
          />
          <path
            d="M722.5 349.9c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint8_linear)"
          />
          <path
            opacity=".27"
            d="M730.2 319.4v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4h-4.8z"
            fill="#D8D8D8"
          />
          <path
            opacity=".28"
            d="M715.7 387c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c0 1.4-1.1 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
          <path
            d="M686.2 319.4H662V394c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zm.8 0h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7H687c2 0 3.7 1.7 3.7 3.7v.7c-.1 2.1-1.7 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M674.2 409.7c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4v49.8c0 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint9_linear)"
          />
          <path
            d="M674.3 349.9c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint10_linear)"
          />
          <path
            opacity=".27"
            d="M682 319.4v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4H682z"
            fill="#D8D8D8"
          />
          <path
            opacity=".1"
            d="M667.4 387c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c.1 1.4-1.1 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
          <path d="M760.3 333h-1.5v84.9h1.5V333z" fill="url(#paint11_linear)" />
          <path
            d="M590.3 333.3h-1.5v84.9h1.5v-84.9z"
            fill="url(#paint12_linear)"
          />
          <path
            d="M780.4 333.3H566.3c-.7 0-1.3-.6-1.3-1.3v-5.4c0-.7.6-1.3 1.3-1.3h214.1c.7 0 1.3.6 1.3 1.3v5.4c.1.7-.5 1.3-1.3 1.3z"
            fill="url(#paint13_linear)"
          />
          <path
            d="M565 361.8v-35.2c0-.7.6-1.3 1.3-1.3h7.1c.7 0 1.3.6 1.3 1.3v35.2c0 .7-.6 1.3-1.3 1.3h-7.1c-.7.1-1.3-.5-1.3-1.3z"
            fill="url(#paint14_linear)"
          />
          <path
            d="M772 361.8v-35.2c0-.7.6-1.3 1.3-1.3h7.1c.7 0 1.3.6 1.3 1.3v35.2c0 .7-.6 1.3-1.3 1.3h-7.1c-.7.1-1.3-.5-1.3-1.3z"
            fill="url(#paint15_linear)"
          />
          <path
            d="M780.4 416.4H566.3c-.7 0-1.3.6-1.3 1.3v5.4c0 .7.6 1.3 1.3 1.3h214.1c.7 0 1.3-.6 1.3-1.3v-5.4c.1-.8-.5-1.3-1.3-1.3z"
            fill="url(#paint16_linear)"
          />
          <path
            d="M565 387.8V423c0 .7.6 1.3 1.3 1.3h7.1c.7 0 1.3-.6 1.3-1.3v-35.2c0-.7-.6-1.3-1.3-1.3h-7.1c-.7-.1-1.3.5-1.3 1.3z"
            fill="url(#paint17_linear)"
          />
          <path
            d="M772 387.8V423c0 .7.6 1.3 1.3 1.3h7.1c.7 0 1.3-.6 1.3-1.3v-35.2c0-.7-.6-1.3-1.3-1.3h-7.1c-.7-.1-1.3.5-1.3 1.3z"
            fill="url(#paint18_linear)"
          />
          <path
            opacity=".28"
            d="M569.9 422.4a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zm0-89.1a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zm207 89.1a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zm0-89.1a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599z"
            fill="#D8D8D8"
          />
          <path
            d="M621.9 355.7c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9 0-1.1-.5-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M622.6 357.6c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.4.1 2.7-1.3 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M618.5 358.1c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M627.1 362.9c.2.2.3.5.3.8 0 .6-.5 1.2-1.1 1.2-.2 0-.4-.1-.6-.2.2.2.5.4.8.4.6 0 1.1-.5 1.1-1.2 0-.5-.2-.8-.5-1z"
            fill="#EBE7D2"
          />
          <path
            d="M627.4 363.6c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.6-.2-.6 0-1.1.5-1.1 1.2 0 .3.1.6.3.8.2.1.4.2.6.2.6 0 1.1-.5 1.1-1.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M625.8 363.8c0-.4.3-.8.7-.8"
            stroke="#fff"
            strokeWidth=".221"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M626.6 385.6c.3.4.6 1 .6 1.6 0 1.3-1 2.4-2.2 2.4-.4 0-.8-.1-1.2-.4.4.5 1 .8 1.6.8 1.2 0 2.2-1.1 2.2-2.4 0-.9-.4-1.6-1-2z"
            fill="#EBE7D2"
          />
          <path
            d="M627.2 387.1c0-.6-.2-1.2-.6-1.6-.3-.2-.8-.4-1.2-.4-1.2 0-2.2 1.1-2.2 2.4 0 .6.2 1.2.6 1.6.3.2.8.4 1.2.4 1.2 0 2.2-1 2.2-2.4z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M623.9 387.6c0-.9.7-1.6 1.5-1.6"
            stroke="#fff"
            strokeWidth=".451"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M624.1 376c.3.4.5.9.5 1.4 0 1.2-.9 2.2-2 2.2-.4 0-.8-.1-1.1-.3.4.4.9.7 1.5.7 1.1 0 2-1 2-2.2 0-.7-.3-1.4-.9-1.8z"
            fill="#EBE7D2"
          />
          <path
            d="M624.6 377.5c0-.6-.2-1.1-.5-1.4-.3-.2-.7-.3-1.1-.3-1.1 0-2 1-2 2.2 0 .6.2 1.1.5 1.4.3.2.7.3 1.1.3 1.1-.1 2-1.1 2-2.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M621.7 377.8c0-.8.6-1.4 1.4-1.4"
            stroke="#fff"
            strokeWidth=".411"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M618.4 337.9c.2.3.3.6.3 1 0 .8-.6 1.5-1.4 1.5-.3 0-.5-.1-.7-.2.3.3.6.5 1 .5.8 0 1.4-.7 1.4-1.5 0-.6-.2-1-.6-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M618.7 338.9c0-.4-.1-.7-.3-1-.2-.1-.5-.2-.7-.2-.8 0-1.4.7-1.4 1.5 0 .4.1.7.3 1 .2.1.5.2.7.2.8-.1 1.4-.7 1.4-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M616.7 339.1c0-.5.4-1 .9-1"
            stroke="#fff"
            strokeWidth=".28"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M625.4 334.9c.2.3.4.6.4 1 0 .8-.7 1.5-1.6 1.5-.3 0-.6-.1-.8-.2.3.3.7.5 1.2.5.9 0 1.6-.7 1.6-1.5-.1-.5-.3-1-.8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M625.8 335.9c0-.4-.1-.7-.4-1-.2-.1-.5-.2-.8-.2-.9 0-1.6.7-1.6 1.5 0 .4.1.7.4 1 .2.1.5.2.8.2.9 0 1.6-.7 1.6-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M623.5 336.2c0-.5.5-1 1.1-1"
            stroke="#fff"
            strokeWidth=".298"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M619 315.4c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M619.7 317.3c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M615.7 317.9c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M625.9 304.1c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.3-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M626.3 305.1c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.8 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M624.1 305.4c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M721.9 356.5c-.4.5-.7 1.2-.7 2 0 1.6 1.2 2.9 2.7 2.9.5 0 1.1-.2 1.5-.5-.5.6-1.2 1-2 1-1.5 0-2.7-1.3-2.7-2.9-.1-1 .4-1.9 1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M721.2 358.5c0-.8.3-1.4.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.7 1.3 2.7 2.9 0 .8-.3 1.4-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.7-1.3-2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M725.2 359c0-1.1-.8-1.9-1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M716.7 363.7c-.2.2-.3.5-.3.8 0 .6.5 1.2 1.1 1.2.2 0 .4-.1.6-.2-.2.2-.5.4-.8.4-.6 0-1.1-.5-1.1-1.2 0-.4.2-.8.5-1z"
            fill="#EBE7D2"
          />
          <path
            d="M716.4 364.5c0-.3.1-.6.3-.8.2-.1.4-.2.6-.2.6 0 1.1.5 1.1 1.2 0 .3-.1.6-.3.8-.2.1-.4.2-.6.2-.6 0-1.1-.6-1.1-1.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M718 364.7c0-.4-.3-.8-.7-.8"
            stroke="#fff"
            strokeWidth=".221"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M717.1 386.4c-.3.4-.6 1-.6 1.6 0 1.3 1 2.4 2.2 2.4.4 0 .8-.1 1.2-.4-.4.5-1 .8-1.6.8-1.2 0-2.2-1.1-2.2-2.4 0-.8.4-1.5 1-2z"
            fill="#EBE7D2"
          />
          <path
            d="M716.6 388c0-.6.2-1.2.6-1.6.3-.2.8-.4 1.2-.4 1.2 0 2.2 1.1 2.2 2.4 0 .6-.2 1.2-.6 1.6-.3.2-.8.4-1.2.4-1.2 0-2.2-1.1-2.2-2.4z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M719.8 388.4c0-.9-.7-1.6-1.5-1.6"
            stroke="#fff"
            strokeWidth=".451"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M719.7 376.9c-.3.4-.5.9-.5 1.4 0 1.2.9 2.2 2 2.2.4 0 .8-.1 1.1-.3-.4.4-.9.7-1.5.7-1.1 0-2-1-2-2.2-.1-.8.3-1.4.9-1.8z"
            fill="#EBE7D2"
          />
          <path
            d="M719.2 378.3c0-.6.2-1.1.5-1.4.3-.2.7-.3 1.1-.3 1.1 0 2 1 2 2.2 0 .6-.2 1.1-.5 1.4-.3.2-.7.3-1.1.3-1.1 0-2-1-2-2.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M722.1 378.7c0-.8-.6-1.4-1.4-1.4"
            stroke="#fff"
            strokeWidth=".411"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M725.4 338.8c-.2.3-.3.6-.3 1 0 .8.6 1.5 1.4 1.5.3 0 .5-.1.7-.2-.3.3-.6.5-1 .5-.8 0-1.4-.7-1.4-1.5-.1-.6.2-1.1.6-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M725 339.7c0-.4.1-.7.3-1 .2-.1.5-.2.7-.2.8 0 1.4.7 1.4 1.5 0 .4-.1.7-.3 1-.2.1-.5.2-.7.2-.8 0-1.4-.7-1.4-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M727 340c0-.5-.4-1-.9-1"
            stroke="#fff"
            strokeWidth=".28"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M718.3 335.8c-.2.3-.4.6-.4 1 0 .8.7 1.5 1.6 1.5.3 0 .6-.1.8-.2-.3.3-.7.5-1.2.5-.9 0-1.6-.7-1.6-1.5.1-.6.4-1 .8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M717.9 336.8c0-.4.1-.7.4-1 .2-.1.5-.2.8-.2.9 0 1.6.7 1.6 1.5 0 .4-.1.7-.4 1-.2.1-.5.2-.8.2-.9 0-1.6-.7-1.6-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M720.2 337.1c0-.5-.5-1-1.1-1"
            stroke="#fff"
            strokeWidth=".298"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M724.8 316.2c-.4.5-.7 1.2-.7 2 0 1.6 1.2 2.9 2.7 2.9.5 0 1.1-.2 1.5-.5-.5.6-1.2 1-2 1-1.5 0-2.7-1.3-2.7-2.9-.1-1 .4-1.9 1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M724.1 318.2c0-.8.3-1.4.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.7 1.3 2.7 2.9 0 .8-.3 1.4-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.7-1.3-2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M728.1 318.7c0-1.1-.8-1.9-1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M717.8 304.9c-.2.3-.4.6-.4 1.1 0 .9.7 1.6 1.5 1.6.3 0 .6-.1.8-.3-.3.3-.7.5-1.1.5-.8 0-1.5-.7-1.5-1.6 0-.5.3-1 .7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M717.5 306c0-.4.1-.8.4-1.1.2-.2.5-.3.8-.3.8 0 1.5.7 1.5 1.6 0 .4-.1.8-.4 1.1-.2.2-.5.3-.8.3-.9 0-1.5-.7-1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M719.6 306.3c0-.6-.5-1-1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M671.2 360.6c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.5-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M671.9 362.5c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M667.9 363.1c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M675.6 371.7c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.2-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M676 372.7c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.9 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M673.8 373c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M677.2 356.8c.2.2.3.5.3.8 0 .7-.5 1.3-1.2 1.3-.2 0-.5-.1-.6-.2.2.3.5.4.9.4.7 0 1.2-.6 1.2-1.3-.1-.4-.3-.8-.6-1z"
            fill="#EBE7D2"
          />
          <path
            d="M677.5 357.7c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.6-.2-.7 0-1.2.6-1.2 1.3 0 .3.1.6.3.8.2.1.4.2.6.2.6-.1 1.2-.6 1.2-1.3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M675.7 357.9c0-.5.4-.8.8-.8"
            stroke="#fff"
            strokeWidth=".244"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M677.5 382.1c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M678.2 384c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.3 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M674.2 384.5c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M674.2 336.6c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.3-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M674.6 337.6c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.8 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M672.4 337.9c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M669.7 314.1c.2.3.4.6.4 1 0 .8-.7 1.5-1.5 1.5-.3 0-.6-.1-.8-.2.3.3.7.5 1.2.5.9 0 1.5-.7 1.5-1.5-.1-.6-.4-1.1-.8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M670.1 315c0-.4-.1-.7-.4-1-.2-.1-.5-.2-.8-.2-.9 0-1.5.7-1.5 1.5 0 .4.1.7.4 1 .2.1.5.2.8.2.8 0 1.5-.6 1.5-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M667.8 315.3c0-.5.5-1 1-1"
            stroke="#fff"
            strokeWidth=".297"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M682.4 301.6c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M683.1 303.5c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M679.1 304.1c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g opacity=".3">
          <path
            d="M531.5 312h-126c-4.8 0-8.6 1.9-8.6 4.3v24.1c0 2.4 3.9 4.3 8.6 4.3h126"
            stroke="url(#paint19_linear)"
            strokeWidth="6.51"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M526.5 341.8H405.1c-4.1 0-7.4-3.3-7.4-7.4v-12c0-4.1 3.3-7.4 7.4-7.4h121.4v26.8z"
            fill="#fff"
          />
          <path
            d="M400.3 320h122.1m-122.1 4.4h122.1m-122.1 4.3h122.1m-122.1 4.4h122.1m-122.1 4.4h122.1"
            stroke="#CDCCCC"
            strokeWidth="2.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M384 349.8h126c4.8 0 8.6 1.9 8.6 4.3v24.1c0 2.4-3.9 4.3-8.6 4.3H384"
            stroke="#066938"
            strokeWidth="6.51"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M389 379.6h121.4c4.1 0 7.4-3.3 7.4-7.4v-12c0-4.1-3.3-7.4-7.4-7.4H389v26.8z"
            fill="#fff"
          />
          <path
            d="M515.2 357.8H393.1m122.1 4.4H393.1m122.1 4.3H393.1m122.1 4.4H393.1m122.1 4.3H393.1"
            stroke="#CDCCCC"
            strokeWidth="2.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M543.8 388.3h-126c-4.8 0-8.6 1.9-8.6 4.3v24.1c0 2.4 3.9 4.3 8.6 4.3h126"
            stroke="url(#paint20_linear)"
            strokeWidth="6.51"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M538.7 418.1H417.3c-4.1 0-7.4-3.3-7.4-7.4v-12c0-4.1 3.3-7.4 7.4-7.4h121.4v26.8z"
            fill="#fff"
          />
          <path
            d="M412.6 396.3h122m-122 4.4h122m-122 4.3h122m-122 4.4h122m-122 4.4h122"
            stroke="#CDCCCC"
            strokeWidth="2.17"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <path
          className={ProjectPageStyles.sparkles}
          d="M996 286.5c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zm4-269c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zm173 364c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM21 110.5c-14.4 0-9.8-17.5-9.8-17.5H9.8s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zm361 217c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zm-361 62c-14.4 0-9.8-17.5-9.8-17.5H9.8s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5z"
          fill="#EFE9E2"
        />
        <g opacity=".3">
          <path
            d="M1086.5 284.5c-5.4 0-9.8-4.4-9.8-9.8v-19.1h-42.5v19.1c0 5.4-4.4 9.8-9.8 9.8-5.1 0-9.3 4.2-9.3 9.3v112.8c0 9.6 7.8 17.3 17.3 17.3h46.2c9.6 0 17.3-7.8 17.3-17.3V293.9c0-5.2-4.2-9.4-9.4-9.4z"
            fill="url(#paint21_linear)"
          />
          <path
            d="M1076.9 255.6H1034c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3h42.9c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3z"
            fill="#3A65AE"
          />
          <path
            opacity=".1"
            d="M1086.5 284.5c-5.4 0-9.8-4.4-9.8-9.8v-19.1h-16v19.1c0 5.4 4.4 9.8 9.8 9.8 5.1 0 9.3 4.2 9.3 9.3v112.8c0 9.6-7.8 17.3-17.3 17.3h16c9.6 0 17.3-7.8 17.3-17.3V293.9c.1-5.2-4.1-9.4-9.3-9.4z"
            fill="#D8D8D8"
          />
          <path
            d="M1015 378.7h45.5c3.3 0 6-2.7 6-6v-44.6c0-3.3-2.7-6-6-6H1015v56.6z"
            fill="#3D3D3B"
          />
          <path
            d="M1015.9 375.9h42.7c2.2 0 3.9-1.5 3.9-3.4v-44.1c0-1.9-1.8-3.4-3.9-3.4H1015"
            stroke="#fff"
            strokeWidth=".316"
            strokeMiterlimit="10"
          />
          <path
            opacity=".1"
            d="M1077.8 245h-7.7c2.4 0 4.4 2 4.4 4.4v1.8c0 2.4-2 4.4-4.4 4.4h7.7c2.4 0 4.4-2 4.4-4.4v-1.8c0-2.4-2-4.4-4.4-4.4z"
            fill="#D8D8D8"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="597"
            y1="491.017"
            x2="597"
            y2="441.062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B0785D" />
            <stop offset=".485" stopColor="#AF775C" />
            <stop offset=".659" stopColor="#A97258" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="847.757"
            y1="392.521"
            x2="799.102"
            y2="392.521"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="860.968"
            y1="413.344"
            x2="881.456"
            y2="333.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BEAF" />
            <stop offset=".76" stopColor="#B4DBD1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="940.373"
            y1="393.004"
            x2="989.038"
            y2="393.004"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="345.241"
            y1="399.037"
            x2="364.624"
            y2="368.458"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#542A14" />
            <stop offset="1" stopColor="#C35E19" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="635.378"
            y1="378.724"
            x2="611.001"
            y2="378.724"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="620.141"
            y1="353.237"
            x2="628.737"
            y2="338.347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F39FA6" />
            <stop offset=".569" stopColor="#DF6171" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="706.309"
            y1="402.283"
            x2="748.134"
            y2="329.841"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".239" stopColor="#F3A0A6" />
            <stop offset=".676" stopColor="#F0EBE4" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="719.394"
            y1="353.237"
            x2="727.991"
            y2="338.347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".239" stopColor="#F3A0A6" />
            <stop offset=".676" stopColor="#F0EBE4" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="658.026"
            y1="402.283"
            x2="699.85"
            y2="329.841"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BEAF" />
            <stop offset=".76" stopColor="#B4DBD1" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="671.11"
            y1="353.237"
            x2="679.707"
            y2="338.347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BEAF" />
            <stop offset=".76" stopColor="#B4DBD1" />
          </linearGradient>
          <linearGradient
            id="paint11_linear"
            x1="758.833"
            y1="375.405"
            x2="760.377"
            y2="375.405"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint12_linear"
            x1="588.805"
            y1="375.731"
            x2="590.349"
            y2="375.731"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint13_linear"
            x1="565.001"
            y1="329.266"
            x2="781.75"
            y2="329.266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint14_linear"
            x1="565.001"
            y1="344.224"
            x2="574.767"
            y2="344.224"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint15_linear"
            x1="771.984"
            y1="344.224"
            x2="781.75"
            y2="344.224"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint16_linear"
            x1="565.001"
            y1="420.365"
            x2="781.75"
            y2="420.365"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint17_linear"
            x1="565.001"
            y1="405.407"
            x2="574.767"
            y2="405.407"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint18_linear"
            x1="771.984"
            y1="405.407"
            x2="781.75"
            y2="405.407"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AF785D" />
            <stop offset=".497" stopColor="#AD775B" />
            <stop offset=".676" stopColor="#A77157" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint19_linear"
            x1="393.655"
            y1="328.368"
            x2="572.445"
            y2="328.368"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".179" stopColor="#F3A0A6" />
            <stop offset=".569" stopColor="#E06071" />
          </linearGradient>
          <linearGradient
            id="paint20_linear"
            x1="405.895"
            y1="404.668"
            x2="547.045"
            y2="404.668"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B0785D" />
            <stop offset=".485" stopColor="#AF775C" />
            <stop offset=".659" stopColor="#A97258" />
            <stop offset=".755" stopColor="#A26C53" />
          </linearGradient>
          <linearGradient
            id="paint21_linear"
            x1="1043.05"
            y1="368.192"
            x2="1087.76"
            y2="297.755"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#542A14" />
            <stop offset="1" stopColor="#C35E19" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
